export enum AccountingSystemsEnum {
    LEXWARE = 'LEXWARE',
    DATEV = 'DATEV',
    LODAS = 'LODAS',
    EDLOHN = 'EDLOHN',
    SAGE = 'SAGE',
    ADDISON = 'ADDISON',
    SAP = 'SAP',
}

export const isStringTypeKeySystem = (
    importType: AccountingSystemsEnum | string
) => {
    return (
        AccountingSystemsEnum.EDLOHN.localeCompare(importType, 'de', {
            sensitivity: 'base',
        }) === 0
    );
};

const SYSTEMS_WITH_TYPE_NUMBERS = [
    AccountingSystemsEnum.LODAS,
    AccountingSystemsEnum.LEXWARE,
    AccountingSystemsEnum.DATEV,
    AccountingSystemsEnum.SAGE,
    AccountingSystemsEnum.ADDISON,
    AccountingSystemsEnum.SAP,
];

export const isNumberTypeKeySystem = (
    importType: AccountingSystemsEnum | string
) => {
    for (const system in SYSTEMS_WITH_TYPE_NUMBERS) {
        if (
            system.localeCompare(importType, 'de', {
                sensitivity: 'base',
            }) === 0
        ) {
            return true;
        }
    }
    return false;
};
